import { BatchHttpLink } from 'apollo-link-batch-http';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { ApolloLink, ApolloClient, InMemoryCache } from 'apollo-boost';
import {
  getAuthToken,
  removeAuthToken,
  removeRefreshToken
} from './auth/utils';
import { gqlErrKey } from './core/config';

export const gqlErrorEvent = new Event('gqlError');

let apolloClient;

const logout = () => {
  removeAuthToken();
  removeRefreshToken();
  window.location.replace(`${process.env.REACT_APP_FRONTEND_URL}?signin=1`);
};

const invalidTokenLink = onError(error => {
  if (error.graphQLErrors) {
    const errors = [];
    for (const err of error.graphQLErrors) {
      // handle errors differently based on its error code
      switch (err.extensions.exception.code) {
        case 'JSONWebTokenError':
        case 'JSONWebTokenExpired':
          return logout();
        //   return fromPromise(
        //     getNewTokens().catch(error => {
        //       // Handle token refresh errors e.g clear stored tokens, redirect to login
        //       return logout();
        //     })
        //   )
        //     .filter(value => Boolean(value))
        //     .flatMap(({ refreshToken, token }) => {
        //       setRefreshToken(refreshToken);
        //       setAuthToken(token);
        //       const headers = error.operation.getContext().headers;
        //       // modify the operation context with a new token
        //       error.operation.setContext({
        //         headers: {
        //           ...headers,
        //           Authorization: `JWT ${token}`
        //         }
        //       });
        //
        //       // replace verified token
        //       if (error.operation.operationName === 'VerifyToken') {
        //         error.operation.variables.token = token;
        //       }
        //
        //       // retry the request, returning the new observable
        //       return error.forward(error.operation);
        //     });
        default:
          errors.push(err.message);
          localStorage.setItem(gqlErrKey, JSON.stringify(errors));
          document.dispatchEvent(gqlErrorEvent);
      }
    }
    return error.forward(error.operation);
  }
});

const authLink = setContext((_, context) => {
  const authToken = getAuthToken();

  return {
    ...context,
    headers: {
      ...context.headers,
      Authorization: authToken ? `JWT ${authToken}` : null
    }
  };
});

// DON'T TOUCH THIS
// These are separate clients and do not share configs between themselves
// so we need to explicitly set them
const linkOptions = {
  credentials: 'same-origin',
  uri: process.env.REACT_APP_BACKEND_URL
};
const uploadLink = createUploadLink(linkOptions);
const batchLink = new BatchHttpLink({
  batchInterval: 100,
  ...linkOptions
});

const link = ApolloLink.split(
  operation =>
    operation.query.definitions.find(i => i.operation === 'mutation') &&
    operation.operationName !== 'VerifyToken',
  uploadLink,
  batchLink
);
apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: invalidTokenLink.concat(authLink.concat(link))
});

export default apolloClient;
